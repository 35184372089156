a {
  text-decoration: none;
}

body {
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
}

.box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
}

.g-btn {
  width: 100% !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 15px !important;
}
.g-btn > div,
.g-btn > div > svg {
  width: 40px !important;
  height: 38px !important;
}
.button-right {
  display: flex;
  justify-content: flex-end;
}

#recaptcha-container {
  margin: 20px;
}
